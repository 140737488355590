import React from 'react'
import { Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import AuthorExpanded from '@widgets/AuthorExpanded'
import { useBlogAuthors } from '@helpers-blog'

export default () => {
  const authors = useBlogAuthors()

  return (
    <>
      <Seo title='Authors' />
      <Divider />
      <Stack effectProps={{ effect: 'fadeInDown' }}>
        <PageTitle
          header='Briefly'
          subheader='I am Lorenzo, fashion designer turned inventor after ten years career into apparel industry, still creating and devoted to trends. Italian. After observing recent trasformations I decided to create this blog, in 2021, because I do believe that fashion industry and more widely our behaviours toward apparel and lifestyle are deeply changing. More important I am lookign forward to hearing your voice on this, if you have something to say join our community and share by following us on Facebook, Instagram or Podcast and comment. If you want to get updates, please sign up the newsletter!'         />
      </Stack>
      <Stack>
        <Main>
          {authors.map((author) => (
            <>
              <Divider />
              <AuthorExpanded author={author} withLink />
            </>
          ))}
        </Main>
      </Stack>
    </>
  )
}
